{{ src / components / Gantt.vue }}
<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pb-1">
      <h3>Diagramme</h3>
      <div class="d-flex">
        <ButtonCircle
          size="22"
          name="zoom-in"
          colorClass="btn-primary"
          @click="zoomIn"
          :padding="1.3"
        ></ButtonCircle>
        <ButtonCircle
          size="22"
          name="zoom-out"
          colorClass="btn-primary"
          @click="zoomOut"
          :padding="1.3"
        ></ButtonCircle>
      </div>
    </div>
    <div id="gantt" ref="gantt"></div>
    <slot></slot>
  </div>
</template>

<script>
import { gantt } from "dhtmlx-gantt";
import locale from "@/config/gantt_locales.json";
import columns from "@/config/gantt_columns.json";
import { zoom } from "@/config/gantt_zooms.js";
import ButtonCircle from "@/components/bases/ButtonCircle.vue";

var event1 = {};
var event2 = {};
var event3 = {};
var event4 = {};
var event5 = {};

export default {
  name: "gantt",
  components: { ButtonCircle },
  data() {
    return {
      zoom: "month",
      lightBox: false
    };
  },
  methods: {
    zoomIn() {
      gantt.ext.zoom.zoomIn();
    },
    zoomOut() {
      gantt.ext.zoom.zoomOut();
    },
    linkAdd(i) {
      this.$emit("linkAdd", i);
    },
    reload() {
      console.log("refresh");
      gantt.parse(this.$props.tasks);
    }
  },
  props: {
    tasks: {
      type: Object,
      default() {
        return { data: [], links: [] };
      }
    }
  },
  mounted: function() {
    // Zoom configuration (config file)
    var zoomConfig = zoom(gantt);

    // Locale config (langage config file)
    gantt.locale = locale;

    // Columns config (config file)
    gantt.config.columns = columns;

    // ADD Link event
    event1 = gantt.attachEvent("onAfterLinkAdd", (e, i) =>
      this.$emit("linkAdd", i)
    );

    // DELETE Link event
    event2 = gantt.attachEvent("onAfterLinkDelete", (e, i) =>
      this.$emit("linkDelete", i)
    );

    // UPDATE task event
    event3 = gantt.attachEvent("onAfterTaskUpdate", (e, i) => {
      this.$emit("taskUpdate", i);
    });

    // Delete task event
    event4 = gantt.attachEvent("onAfterTaskDelete", (e, i) =>
      this.$emit("taskDelete", i)
    );

    // LightBox event
    event5 = gantt.attachEvent("onBeforeLightbox", task_id => {
      this.$emit("selectPhase", task_id);
    });

    //gantt.config.server_utc = true;
    gantt.config.date_grid = "%d-%M-%Y";
    gantt.config.xml_date = "%Y-%m-%d";

    //zoom
    gantt.ext.zoom.init(zoomConfig);
    gantt.ext.zoom.setLevel("month");

    // Styles
    gantt.templates.grid_header_class = function() {
      return "text-dark";
    };

    // Run
    gantt.init(this.$refs.gantt);
    gantt.clearAll();
    gantt.parse(this.$props.tasks);
  },
  beforeDestroy() {
    gantt.detachEvent(event1);
    gantt.detachEvent(event2);
    gantt.detachEvent(event3);
    gantt.detachEvent(event4);
    gantt.detachEvent(event5);
  }
};
</script>

<style>
@import "~dhtmlx-gantt/codebase/dhtmlxgantt.css";

.modal-form {
  position: absolute;
  width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2100;
}

.weekend {
  background: #dfe3e5 !important;
}

.cell.day.weekend {
  background: none !important;
}
</style>

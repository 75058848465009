<template>
  <div class="container-gantt modal-container-chart active" v-background-1>
    <router-link :to="{ name: 'crm_affaire_planning' }">Fermer</router-link>
    <gantt
      ref="gant"
      :tasks="ganttData"
      @taskUpdate="updatePhase"
      @linkAdd="createPhaseLink"
      @linkDelete="deletePhaseLink"
      @selectPhase="selectPhase"
    >
      <div
        id="my-form"
        class="modal-form border shadow-sm rounded pl-3 pr-3 pt-2"
        v-background-3
        v-if="selectedPhaseId"
      >
        <div v-for="phase in phases" :key="phase.id" class="w-100">
          <base-form
            backBtn
            deleteBtn
            v-if="phase.id == selectedPhaseId"
            @click="updatePhase(phase)"
            @delete="deletePhase(phase)"
            @back="selectedPhaseId=0"
            :loading="(loadingPhaseUpdate || loadingPhaseDelete)"
            class="pb-5"
          >
            <div class="mb-3">
              <h4>Phase {{phase.text}}</h4>
              <base-form-row row>
                <base-input v-model="phase.text" inputText="Description"></base-input>
              </base-form-row>
              <base-form-row row>
                <base-input-date-picker v-model="phase.start_date" inputText="Date de début"></base-input-date-picker>
                <BaseSlideNumber
                  v-model.number="phase.duration"
                  text="Durée (jrs)"
                  :max="100"
                  :min="0"
                ></BaseSlideNumber>
              </base-form-row>
              <base-form-row row>
                <BaseSlideNumber
                  v-model.number="phase.progress"
                  text="Progression (%)"
                  :max="100"
                  :min="0"
                  percent
                ></BaseSlideNumber>
              </base-form-row>
            </div>
          </base-form>
        </div>
      </div>
    </gantt>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapActions, mapGetters } from "vuex";
import { mapMultiRowFields } from "vuex-map-fields";
import Gantt from "@/components/bases/Planning.vue";
import BaseForm from "@/components/bases/Form.vue";

import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInput from "@/components/bases/Input.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseSlideNumber from "@/components/bases/SlideNumber.vue";

export default {
  components: {
    Gantt,
    BaseForm,
    BaseFormRow,
    BaseInput,
    BaseInputDatePicker,
    BaseSlideNumber
  },
  data() {
    return {
      active: false,
      loadingPhaseCreate: false,
      loadingPhaseUpdate: false,
      loadingPhaseDelete: false,
      selectedPhaseId: 0
    };
  },
  methods: {
    ...mapActions({
      _createPhase: "affaires/createPhase",
      _updatePhase: "affaires/updatePhase",
      _deletePhase: "affaires/deletePhase",
      _createPhaseLink: "affaires/createPhaseLink",
      _deletePhaseLink: "affaires/deletePhaseLink"
    }),
    createPhase(affaire_id) {
      this.loadingPhaseCreate = true;
      this._createPhase(affaire_id)
        //.then(response => (this.newPhase = response.data))
        .finally(() => (this.loadingPhaseCreate = false));
    },
    updatePhase(phase) {
      this.loadingPhaseUpdate = true;
      this._updatePhase(phase)
        .then(() => this.$refs.gant.reload())
        .finally(() => (this.loadingPhaseUpdate = false));
    },
    deletePhase(phase) {
      this.loadingPhaseDelete = true;
      this._deletePhase(phase).finally(() => (this.loadingPhaseDelete = false));
    },
    createPhaseLink(link) {
      this.loadingPhaseCreate = true;
      link = Object.assign({ affaire_id: this.id }, link);
      this._createPhaseLink(link).finally(
        () => (this.loadingPhaseCreate = false)
      );
    },
    deletePhaseLink(link) {
      this.loadingPhaseDelete = true;
      this._deletePhaseLink(link).finally(
        () => (this.loadingPhaseDelete = false)
      );
    },
    selectPhase(id) {
      this.selectedPhaseId = id;
    }
  },
  computed: {
    ...mapGetters({
      ganttData: "affaires/dataForGant"
    }),
    ...mapFields("affaires", ["affaire.id"]),
    ...mapMultiRowFields("affaires", ["phases"]),
    ...mapMultiRowFields("affaires", ["links"])
  }
};
</script>
<style lang="css">
.modal-container-chart {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: auto;
}

.modal-container-chart.active {
  position: absolute;
  background: white;
  padding: 20px;
  z-index: 2000;
}

.container-gantt {
  max-width: 100%;
}

#gantt {
  background: white;
  height: 100vh;
}
</style>

<template>
  <div class="d-flex flex-column p-1 align-items-start w-100">
    <div class="d-flex justify-content-between align-items-center w-100">
      <div class="mr-2">{{ text }}</div>
      <div class="d-flex align-items-center">
        <input
          type="Number"
          class="border-0 text-right mr-2"
          :class="textClass"
          style="min-width:100px;"
          v-bind="$attrs"
          v-model="inputValue"
          :min="min"
          :max="max"
          :step="step"
        />
        <div @click="plus" class="cursor-pointer">
          <base-icon name="plus" color="#007bff" class="ml-1" width="18" height="18" />
        </div>
        <div @click="moins" class="cursor-pointer">
          <base-icon name="minus" color="#007bff" class="ml-1" width="18" height="18" />
        </div>
      </div>
    </div>
    <input
      tabindex="-1"
      type="range"
      class="custom-range border-0 w-100"
      :step="step"
      v-model="inputValue"
      :min="min"
      :max="max"
    />
  </div>
</template>
<script>
import BaseIcon from "@/components/bases/Icon.vue";

export default {
  components: { BaseIcon },
  inheritAttrs: false,
  props: {
    value: {
      type: Number,
      default: 0
    },
    text: {
      type: String,
      default: "title"
    },
    min: {
      type: Number,
      required: false
    },
    max: {
      type: Number,
      required: false
    },
    step: {
      type: Number,
      default: 1
    },
    percent: {
      type: Boolean,
      default: false
    },
    darkBackgroundMode: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    plus: function() {
      this.$emit("input", parseFloat(this.value) + 1);
    },

    moins: function() {
      this.$emit("input", parseFloat(this.value) - 1);
    }
  },
  computed: {
    textClass: function() {
      if (!this.$store.getters["colors/darkMode"] && !this.darkBackgroundMode) {
        return "text-dark";
      } else {
        return "text-white";
      }
    },

    labelClass: function() {
      if (!this.$store.getters["colors/darkMode"] && !this.darkBackgroundMode) {
        return "label-dark";
      } else {
        return "label-white";
      }
    },
    inputValue: {
      get() {
        if (this.percent) {
          return parseFloat(this.value) * 100;
        } else {
          return this.value;
        }
      },
      set(e) {
        if (this.percent) {
          this.$emit("input", parseFloat(e) / 100);
        } else {
          this.$emit("input", parseFloat(e));
        }
      }
    }
  }
};
</script>
<style></style>

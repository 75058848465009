export function zoom(gantt) {

  var daysStyle = function (date) {
    var dateToStr = gantt.date.date_to_str("%D");
    if (dateToStr(date) == "Dim" || dateToStr(date) == "Sam") return "weekend";
    return "";
  };

  return {
    levels: [
      {
        name: "day",
        scale_height: 50,
        min_column_width: 110,
        scales: [{ unit: "month", format: "%F %Y" }, { unit: "day", step: 1, format: "%l %d", css: daysStyle }]
      },
      {
        name: "month",
        scale_height: 50,
        min_column_width: 50,
        scales: [
          { unit: "month", format: "%F, %Y" },
          { unit: "day", format: "%D %j", css: daysStyle }
        ]
      },
      {
        name: "year",
        scale_height: 50,
        min_column_width: 20,
        scales: [
          { unit: "year", step: 1, format: "%Y" },
          { unit: "month", format: "%F" },
        ]
      }
    ]
  }
}